@import "./styles/reset.css";
@import "./styles/fonts.css";
@import "bootstrap-icons/font/bootstrap-icons.css";

@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
  html {
    font-family: InterVar, system-ui, sans-serif;
  }

  #root, #root>div {
    height: 100%
  }
}




@media only screen and (max-width: 4000px) {
  html {
    font-size: 21px;
  }
}

@media only screen and (max-width: 2560px) {
  html {
    font-size: 18px;
  }
}

@media only screen and (max-width: 1920px) {
  html {
    font-size: 16px;
  }
}

@media only screen and (max-width: 1680px) {
  html {
    font-size: 15px;
  }
}

@media only screen and (max-width: 1440px) {
  html {
    font-size: 14px;
  }
}

@media only screen and (max-width: 1366px) {
  html {
    font-size: 13px;
  }
}

@media only screen and (max-width: 1024px) {
  html {
    zoom:0.6;
  }
}
