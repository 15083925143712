
table, caption, tbody, tfoot, thead, tr, th, td,
html, body, div, span, applet, object, iframe, input,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video, button {
	margin: 0;
	padding: 0;
	font-size: 100%;
	font: inherit;
	box-sizing: border-box;
	letter-spacing: 0.005rem;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

html, body {
    width:100%;
    height: 100%;
	/* font-size: 14px; */
  }

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  /* background: #333A4466; */
  background: #24282F;
  border-radius:100vh;
  margin-block:.5em;
}

::-webkit-scrollbar-thumb {
  /* background: #40495088; */
  /* background: #34353B; */
  background: #3B3C44;
  border-radius:100vw;
}

::-webkit-scrollbar-thumb:hover {
  background: #5A626F77;
}

@supports(scrollbar-color: #34353B #24282F){
  * {
      scrollbar-color: #34353B #24282F;
      scrollbar-width: thin;
  }
}
